export const cardTemplate = {
    url: `https://raw.githubusercontent.com/AICorpGlobal/public-buyconn-release-artifiacts/master/buyconn-templates/workflow-defenitions/amk/new-customer/cards.json`
}

export const workFlowTemplate = {
    url: `https://raw.githubusercontent.com/AICorpGlobal/public-buyconn-release-artifiacts/master/buyconn-templates/workflow-defenitions/amk/new-customer/workflow.json`

}
export const customerEnquiryListTableHeading = [
    { field: "", minWidth: 20, maxWidth: 50, checkboxSelection: true },
    {
        field: "Name",
        editable: false,
        rowDrag: true,
        cellRenderer: (params: any) =>
            params.data?.name
                ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.name.slice(0, 500)}</p>`
                : "-",
        minWidth: 200,
        maxWidth: 800,
        // headerCheckboxSelection: true,
        // checkboxSelection: true,
        // cellRendererParams: {
        //     checkboxSelection: function (params: any) {
        //         return {
        //             suppressMenu: true,
        //             width: 30, // Adjust width if needed
        //             height: 6, // Adjust height if needed
        //             // Add more properties as needed
        //         };
        //     },
        // },
    },
    {
        field: "Type",
        editable: false,
        rowDrag: true,
        cellRenderer:
            (params: any) =>
                params.data?.workflowType
                    ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.workflowType.slice(0, 500)}</p>`
                    : "-",
        minWidth: 200,
        maxWidth: 800,
    },
    {
        field: "state",
        editable: false,
        rowDrag: true,
        cellStyle: (params: any) => {
            if (params.value === 'Active') {
                return { backgroundColor: '#425942' };
            } else if (params.value === 'Inactive') {
                return { backgroundColor: 'rgb(140, 107, 107)' };
            } else if (params.value === 'Pending') {
                return { backgroundColor: 'rgb(151, 77, 77)' };
            }
            return null;
        },
        cellRenderer:
            (params: any) =>
                params.data?.state
                    ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.state.slice(0, 500)}</p>`
                    : "-",
        minWidth: 200,
        maxWidth: 800,
    },
    {
        field: "priority",
        editable: false,
        rowDrag: true,
        cellStyle: (params: any) => {
            if (params.value === 'High') {
                return { backgroundColor: 'rgb(80, 122, 136)' }; // Light Blue
            } else if (params.value === 'Medium') {
                return { backgroundColor: 'rgb(101, 124, 138)' }; // Sky Blue
            } else if (params.value === 'Low') {
                return { backgroundColor: 'rgb(83, 125, 159)' }; // Steel Blue
            }
            return null;
        },
        cellRenderer:
            (params: any) =>
                params.data?.priority
                    ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.priority}</p>`
                    : "-",
        minWidth: 200,
        maxWidth: 800,
    },
    {
        field: "createdOn\t",
        editable: false,
        rowDrag: true,
        cellRenderer:
            (params: any) =>

                params.data?.createdOn
                    ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${new Date(params.data?.createdOn).toLocaleDateString('en-GB')}</p>`
                    : "-",

        minWidth: 200,
        maxWidth: 800,
    }
    ,
    {
        field: "Edit",
        lockPosition: "right",
        cellRenderer:
            (params: any) => `<i class="text-lg fas fa-user-edit"></i>`, // Ensure correct Font Awesome icon class
        minWidth: 50,
        maxWidth: 100,
    },
    {
        field: "comments",
        lockPosition: "right",
        cellRenderer: (params: any) => `<i class="text-lg fa fa-comment"></i>`,
        minWidth: 50,
        maxWidth: 80,
    },
    {
        field: "Open",
        lockPosition: "right",
        cellRenderer: (params: any) => `<i class="text-lg fas fa-arrow-alt-circle-right"></i>`,
        minWidth: 50,
        maxWidth: 100,
    }
    ,
    

    {
        field: "Delete",
        lockPosition: "right",
        cellRenderer:
            (params: any) => `<ArgonButton data-action="open" class="action-button"><i class="text-lg fas fa-trash"></i></ArgonButton>`, // Ensure correct Font Awesome icon class
        minWidth: 50,
        maxWidth: 100,
    },
]
    ;
export const defaultEnquiryColDef = {
    flex: 1,
    minWidth: 150,
    sortable: true,
    resizable: true,
};

export const fieldsToExcludeinWorkFlow = [
    'createdBy',
    'createdOn',
    'triggers',
    'events',
    'actions',
    'addedAtUtc',
    "correlationId",
    'lastModifiedBy',
    'lastModifiedOn',
    'correlationId',
    'version',
    'lastUpdateTimeStamp',
    // 'totalEffortInHours',
    'remainingEffortInHours',
    'effortInHours',
    'points',
    'areaPath',
    'tags',
    'sellerId',
    'regionId',
    'storeId',
    'userId',
    'workflowId',
    'saved',
    'lastUpdateTime',
    'startDate',
    'endDate'

];

export const stateConfig = [
    "Active", "InActive"]

export const OmitDataModel = [
'createdOn',
"lastUpdateTimeStamp",
"createdOn",
"addedAtUtc",
"correlationId",
'lastModifiedBy',
"lastModifiedOn",
"version",
"saved",
"startDate",
'endDate',
"lastUpdateTime"
]