const inputFieldConstants = {
  name: {
    id: "userName",
    label: "Name",
    placeHolder: "Name",
    componentName: "Name",
  },

  email: {
    id: "email",
    label: "Email",
    placeHolder: "Email",
    componentName: "Email",
  },

  phoneNumber: {
    id: "phoneNumber",
    label: "Phone Number",
    placeHolder: "Phone Number",
    componentName: "Phone Number",
  },

  permissions: {
    id: "permissions",
    label: "Permissions",
    placeHolder: "",
    componentName: "Permissions",
  },

  address: {
    id: "address",
    label: "Address",
    placeHolder: "user Address",
    componentName: "Address",
  },

  pinCode: {
    id: "pincode",
    label: "Pincode",
    placeHolder: "Pincode",
    componentName: "Pincode",
  },

  city: {
    id: "city",
    label: "City",
    placeHolder: "City",
    componentName: "City",
  },

  district: {
    id: "district",
    label: "District",
    placeHolder: "District",
    componentName: "District",
  },


  state: {
    id: "state",
    label: "State",
    placeHolder: "State",
    componentName: "State",
  },

  country: {
    id: "country",
    label: "Country",
    placeHolder: "Country",
    componentName: "Country",
  }

};


export function  createNewCssStyle(isDarkMode: Boolean){
  return `
  <style>
    .swal2-input {
      font-size: 12px; 
      padding: 5px; 
      background-color: ${isDarkMode ? "#111c44" : "#fff"}; 
      color: ${isDarkMode ? "#fff" : "#000"};
    }
.swal2-styled.swal2-cancel {
      font-size: 15px; 
      padding: 5px 10px;
      border-radius: 0.5rem;
    }
            .swal2-styled.swal2-confirm {
      font-size: 15px; 
      padding: 5px 10px;
    }
  </style>
  <h2 style="font-size: 1.0rem; margin: 0;">Enter New User Name</h2>
`
}


export { inputFieldConstants };
