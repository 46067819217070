<template>
 
  <div class="mt-3">
    <div class="card mt-4 pb-5"  style="position: relative; margin: 20px;">
      <img src="@/assets/img/commingSoon1.png" alt="Product Statistics" class="top-left-image" />
    <div v-for="(chart, index) in charts" :key="index" class="card my-3 py-2" style="opacity:.4;">
        <div class="chart-title p-4">
        {{ chart.name }} 
        <span class="tag" style="margin-left: 3px">PRO ✨</span>
      </div>
      <div ref="lineCharts" class="chart" ></div>
    </div>
    </div>
</div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "MultiLineChart",
  data() {
    return {
        charts: [
    { name: "Commodity Insights Overview", data: [0, 0, 0, 0, 0, 0, 0] },
    { name: "Commodity Sales Trends", data: [0, 0, 0, 0, 0, 0, 0] },
    { name: "Commodity Sales Performance", data: [0, 0, 0, 0, 0, 0, 0] },
    { name: "Units Sold by Commodity", data: [0, 0, 0, 0, 0, 0, 0] },
    { name: "Revenue by Commodity", data: [0, 0, 0, 0, 0, 0, 0] },
    { name: "Location-Based Sales Trends", data: [0, 0, 0, 0, 0, 0, 0] },
],
    };
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts() {
      this.charts.forEach((chart, index) => {
        const chartElement = this.$refs.lineCharts[index];
        const myChart = echarts.init(chartElement);

        const option = {
          title: {
            text: ``,
        subtext: 'No data found',
        left: 'center',
        top: 'top',
        textStyle: {
            fontSize: 10,
            fontWeight: 'bold',
            color: '#7c7d86'
        },
          },
          tooltip: {
            trigger: "axis",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: chart.name,
              type: "line",
              data: chart.data,
              areaStyle: {},
            },
          ],
        };

        myChart.setOption(option);
      });
    },
  },
};
</script>

<style scoped>
.chart {
  width: 800px;
  height: 400px;
  margin-bottom: 20px;
}

.tag {
  /* position: absolute; */
  /* top: 4px; */
  background-color: #144888;
  color: white;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
}
.chart-title {
  display: flex;
  align-items: center; /* Center the title and tag vertically */
  font-size: 12px; /* Adjust font size */
  font-weight: bold; /* Make the title bold */
  margin-bottom: 10px; /* Space between title and chart */
}
.top-left-image {
  position: absolute;
  top: -2px; /* Adjust as needed */
  left: -6px; /* Adjust as needed */
  width: 180px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  z-index: 10; /* Ensure it's above other elements */
  transform: rotate(270deg);
}
</style>
