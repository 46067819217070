import { ILoyaltyDataModel } from "@/Interfaces/DataModel/LoyaltyDataModel";
import ApiService from "../Common/ApiService";
import { Loyalty } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";


function CreateLoyaltyDefinition(sellerId: string, data: ILoyaltyDataModel): Promise<IResponseDataModel<ILoyaltyDataModel>> {
    const { url, method }: { url: string, method: string } = Loyalty.createLoyalty(sellerId);
    return ApiService.SendRequest(url, method, { data })
}

function GetAllLoyaltyDefinition(sellerId: string ): Promise<IResponseDataModel<ILoyaltyDataModel[]>> {
    const { url, method }: { url: string, method: string } = Loyalty.getAllLoyalty(sellerId);
    return ApiService.SendRequest(url, method)
}


export {
    CreateLoyaltyDefinition,
    GetAllLoyaltyDefinition
}