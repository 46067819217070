import swal from "sweetalert2";

async function confirmToDelete(isDarkMode) {
  const result = await swal.fire({
    html: createNewCssStyle(isDarkMode, "Confirm To Delete"),
    showCancelButton: true,
    confirmButtonText: 'Delete',
    showLoaderOnConfirm: true,
  });

  return result.isConfirmed
}

async function confirmToProceed(isDarkMode, text = "") {
  const result = await swal.fire({
    html: createNewCssStyle(isDarkMode, "Confirm To Proceed"),
    text: text,
    showCancelButton: true,
    confirmButtonText: 'Ok',
    showLoaderOnConfirm: true,
  });

  return result.isConfirmed
}


export function createNewCssStyle(isDarkMode: Boolean, text: string) {
  return `
  <style>
    .swal2-input {
      font-size: 12px; 
      padding: 5px; 
      background-color: ${isDarkMode ? "#111c44" : "#fff"}; 
      color: ${isDarkMode ? "#fff" : "#000"};
    }
    .swal2-styled.swal2-cancel {
      font-size: 15px; 
      padding: 5px 10px;
      border-radius: 0.5rem;
    }
    .swal2-styled.swal2-confirm {
      font-size: 15px; 
      padding: 5px 10px;
    }
  </style>
  <h2 style="font-size: 1.0rem; margin: 0;">${text}</h2>
  `;
}



export { confirmToDelete as confirmToDeleteWarning, confirmToProceed as confirmToProceedWarning }