import {
    IBroadCastMessageViewModel,
    ICustomerActiveViewModel,
    ICustomerDataModel,
    ICustomerProfileListViewModel,
    ICustomerTableActiveViewModel,
    IDynamicFields
} from "@/Interfaces/DataModel/CustomerDataModel";
import { getAllCustomers, getACustomer, removeCustomer, createACustomer, updateACustomerProfile, searchCustomers, MessageSendingApi } from "@/service/apiService/CustomerService";
import { keyAndValueExistsInObject, stringIsValid, validateEmail, validatePhoneNumber } from "./Common/utilityService";
import { commodityDataTypes, customerDataTypes, ViewModelStatus } from "@/config/dataConfig";
import { NonEditablefield } from "@/views/configs/customerConfigs";


export async function getAllCustomersProfile(sellerId: string, page: number) {
    const data: any = await getAllCustomers(sellerId, page);
    return data
}

export async function getCustomerProfilesBySearch(sellerId: string, params?: any) {
    const data: any = await searchCustomers(sellerId, params)
    return data
}
export async function getACustomerProfile(customerProfileDataModel:ICustomerDataModel) {
    const data: any = await getACustomer(customerProfileDataModel.sellerId, customerProfileDataModel.customerId);
    return data.result
}
export async function removeCustomerProfile(customerProfileDataModel:ICustomerDataModel) {
    const data: any = await removeCustomer(customerProfileDataModel.sellerId, customerProfileDataModel.customerId);
    return data
}
export async function updateOrNewCustomerProfile(customerProfileDataModel:ICustomerDataModel) {
    const data: any = customerProfileDataModel.customerId ? await updateACustomerProfile(customerProfileDataModel.sellerId, customerProfileDataModel) :
        await createACustomer(customerProfileDataModel.sellerId, customerProfileDataModel);
    return data
}


export async function customerMessageSending(sellerId:string, messageDataModel:IBroadCastMessageViewModel) {
    const data: any = await MessageSendingApi(sellerId, messageDataModel)
    return data
}


export function convertDataModelToViewModel(DataModel: ICustomerDataModel): ICustomerActiveViewModel {
    return {
        customerId: DataModel.customerId,
        sellerId: DataModel.sellerId,
        storeId: DataModel.storeId,
        userId: DataModel.userId,
        regionId: DataModel.regionId,
        name: DataModel.name,
        accountStatus: DataModel.accountStatus,
        email: DataModel.email,
        phoneNumber: DataModel.phoneNumber,
        metadata: updateMetadata(DataModel),
        saved: DataModel.customerId ? ViewModelStatus.Saved : ViewModelStatus.New
    }
}
export const updateMetadata = (DataModel:ICustomerDataModel) => {
    // const whatsappMetadata = DataModel.metadata?.find(item => NonEditablefield.includes(item.name));
    // if (whatsappMetadata) {
    //   whatsappMetadata.value = whatsappMetadata.value==="true" ||whatsappMetadata.value===true ?true:false; 
    // }
    return DataModel.metadata; 
  };
export function customerProfileValidation(
    customerActiveViewModel: ICustomerActiveViewModel,
    customerProfileActiveTableViewModel: IDynamicFields[]
): { isSuccess: boolean, errorMessage: string } {

    const isNameValid = IsCustomerProfileNameValid(customerActiveViewModel.name);

    if (!isNameValid) {
        return { isSuccess: false, errorMessage: "customerProfile name missing" }
    }

    const customerPhoneNumberValid = validatePhoneNumber(customerActiveViewModel.phoneNumber);
    if (!customerActiveViewModel.phoneNumber.trim() && !customerActiveViewModel.email.trim()) {
        
        return { isSuccess: false, errorMessage: "Either email or phone number must be provided" }
    }
    const customerEmailValid = validateEmail(customerActiveViewModel.email);
    if (customerActiveViewModel.email.trim()) {
       
        if (!customerEmailValid) {
            return { isSuccess: false, errorMessage: "Email is invalid" }
        }
    }
    if (customerActiveViewModel.phoneNumber.trim()) {
        if ( ! customerPhoneNumberValid ) {
            return { isSuccess : false , errorMessage : "Phone number is invalid" }
        }
    }
    const isAllFieldHasValues = IsAllFieldsHasValues(customerProfileActiveTableViewModel);

    if (!isAllFieldHasValues) {
        return { isSuccess: false, errorMessage: "Empty values found" }
    }



    const isAllDataTableFieldTypeIsValid = validateTableDataTypes(customerProfileActiveTableViewModel)

    if (!isAllDataTableFieldTypeIsValid) {
        return { isSuccess: false, errorMessage: "Invalid Field type found" }
    }



    return { isSuccess: true, errorMessage: "" }

}
function IsCustomerProfileNameValid(name: string): boolean {
    return stringIsValid(name);
}

function IsAllFieldsHasValues(
    customerProfileActiveTableViewModel: IDynamicFields[]
): boolean {
    return customerProfileActiveTableViewModel.every((tableRow) =>
        keyAndValueExistsInObject(tableRow)
    );
}

function validateTableDataTypes(customerProfileActiveTableViewModel: IDynamicFields[]) {
    return customerProfileActiveTableViewModel.every((row: any) => {
        if (row.type == customerDataTypes.number) {
            return typeof row.value == "number" || !isNaN(row.value)
        } else {
            return typeof row.value == "string"
        }
    })
}

export function convertToBroadcastDataModel(whatsAppBroadcastMessageViewModel:any,broadcastMessageSendingProfile:any[]){

    const phoneNumbers = [];
    broadcastMessageSendingProfile.forEach(item => {
    const phoneNumber = item.phoneNumber;
    phoneNumbers.push(phoneNumber);
});
const emptyModel ={
    regionId:whatsAppBroadcastMessageViewModel.regionId,
        sellerId:whatsAppBroadcastMessageViewModel.sellerId,
        storeId: whatsAppBroadcastMessageViewModel.storeId,
        userId:whatsAppBroadcastMessageViewModel.userId,
        templateId: whatsAppBroadcastMessageViewModel.templateId,
        messageBody: whatsAppBroadcastMessageViewModel.messageBody.ops[0].insert,
        recipients: phoneNumbers
        
}
return emptyModel
}