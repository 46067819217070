import {IResponseDataModel} from "@/Interfaces/DataModel/ResponseDataModel"
import { parse } from "@vue/compiler-dom";
import { useToast } from "vue-toastification";
const toast = useToast();

function tempParser(stringErrorMsg) {
    try{
       const temp = JSON.parse(stringErrorMsg.slice(0, stringErrorMsg.length-5))
       stringErrorMsg = temp.stringErrorMessage
       tempParser(temp.stringErrorMessage)
    }catch(err) {
        console.log(err)
    }

return stringErrorMsg
}

function AuthErrorNotification<T>(key: string, response?: IResponseDataModel<T>)
{
    if(response) {
    switch(response.responseCode) {
        case 403: {
            toast.error(tempParser(response.stringErrorMessage))
            break;
            }
        case 409: {
            toast.error(tempParser(response.stringErrorMessage))
            break
        }    
        case 500: {
            toast.error('Service is unavailable at the moment')
            break
        }
       
    } }
    else {
        toast.error('Network error')
    }
}
function ErrorNotification<T>(key: string, response?: IResponseDataModel<T>)
{
    if(response) {
        switch(response.responseCode) {
            /**  
             * axios give status 0, if request get blocked
             */
            case 0: {
                toast.error(`Network error`)
                break;
            }
            case 403: {
                toast.error(`You don't have permission`)
                break;
                }
            case 404: {
                toast.error(` No saved ${key} found  ` )
                break;
                }
            case 500: {
                toast.error('Service is unavailable at the moment')
                break;
            }
            case 415:{
                toast.error(`Please contact customer service`)
                break;

            }
            case 400:{
                
                toast.error(response.stringErrorMessage?response.stringErrorMessage:'Please fill incomplete data')
                break;
            }
          
           
        } }
        else {
            toast.error('Network error')
        }
}
function OtpErrorNotification()
{
    toast.error("Failed to sent the  Otp")
}
function InviteCodeErrorNotification()
{
    toast.error("Invite code is invalid")
}
function ValidationErrorNotification(key:string)
{
    toast.error(`You can't delete ${key}`)
}
function ResetPasswordErrorNotification()
{
    toast.error("Password reset has been failed")
}

function NotEligibleForDelete(key :string)
{
    toast.error(`At least one ${key} required`)
}
function UnSavedChanges()
{
    toast.error("You have unsaved changes, Please Save it")
}
function RequiredFiledCheck()
{
    toast.error("Please fill the required fields")

}
function RequiredSpecifyFiledCheck(key:string)
{
    toast.error(`Please fill the ${key} fields`)

}
function NoChangesMade() {
    toast.error("No changes made")
}
function reCheckNeed() {
    toast.error("Recheck the fields")
}
function NotEligible(key :string)
{
    toast.error(`You can't delete the ${key}`)
}
function limitCrossed(){
    toast.error(`You can't add more than 2 key else added empty fields`)
}
function searchresultEmpty(){
    toast.error(`Search result empty`)
}

function customerNotSelected(){
    toast.error(`Please select customers before select broadcast`)
}

function broadcastMessageFailed(){
    toast.error(`Failed to send Broadcast Message`)
}

export {RequiredSpecifyFiledCheck,broadcastMessageFailed,customerNotSelected,searchresultEmpty,limitCrossed, NotEligible,AuthErrorNotification ,reCheckNeed, InviteCodeErrorNotification, ErrorNotification,OtpErrorNotification,ValidationErrorNotification,ResetPasswordErrorNotification,NotEligibleForDelete,UnSavedChanges,RequiredFiledCheck, NoChangesMade}