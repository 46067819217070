<template>
  <div v-if="visible" class="modal">
    <div class="modal-content" :style="{ backgroundColor: isDarkMode ? '#181733' : '#fff' }">
      
      <!-- Flex container for close button and title -->
      <div class="header d-flex justify-content-between align-items-center">
        <!-- Title container with flex-grow to center the title -->
        <div class="title-container" style="flex-grow: 1; display: flex; justify-content: center;">
          <label style="font-size: 18px; font-weight: 900;">Enter Your Card Details</label>
        </div>
        
        <!-- Close button -->
        <ArgonButton
            :color="isDarkMode ? 'white' : 'primary'"
            :variant="isDarkMode ? 'outline' : 'outline'"
            class="close-button"
            @click="close"
        >
          Close
        </ArgonButton>
      </div>

      <!-- Modal Content Slot -->
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from "vuex";

const store = useStore();
const isDarkMode = computed(() => store.state.argon.darkMode);
const props = defineProps({
  visible: Boolean,
});
const emit = defineEmits(["closeModal"]);

const close = () => {
  emit("cardEnquirycloseModal", false);
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  position: relative;
  z-index: 1001;
  width: 50%;
  margin-right: 20px;
  max-height: 90vh;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;  /* Ensure button stays on the right */
  align-items: center;  /* Vertically center-align the button and title */
  margin-bottom: 20px;  /* Optional: Add margin below the header */
}

.title-container {
  display: flex;
  justify-content: center;  /* Center the title horizontally */
}

.close-button {
  position: relative;  /* Ensure close button doesn't overlap */
  z-index: 1010;
}
</style>
