import { IStoreDataModel } from '@/Interfaces/DataModel/StoreDataModel';
import store from '@/store'
import { GetAllStore } from './apiService/StoreService';

type IStore = { storeName: string; storeId: string };

function modifyStoreData(storeData: IStoreDataModel): IStore {
    return {
        storeName: storeData.storeName,
        storeId: storeData.storeId
    }
}

function getStoreDataFromState() {
    return store.getters["stores/getStores"];
}

function setStoreDataToState(modifiedStoreData: IStore) {
    return store.dispatch("stores/setStores", modifiedStoreData);
}

export async function loadStoreList(sellerId: string) {

    let stores = getStoreDataFromState()

    if (stores.length === 0) {
        const resp = await GetAllStore(sellerId);
        if (resp.isSuccess && resp.result) {
            stores = resp.result.map(modifyStoreData);
            setStoreDataToState(stores)
        }
    }

    return stores


}


const convertToTime = (isoTime: string): string => {
    const timePortion = isoTime.split('T')[1]; 
    const [hourStr, minute] = timePortion.split(':');
    const hour = parseInt(hourStr, 10);
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; 
    return `${hour12}:${minute} ${period}`;
};


export function transformedSchedule(schedule) {
    const hourmodel = Object.entries(schedule).map(([day, daySchedule]) => {
        const start = convertToTime(daySchedule["Open"]);
        const end = convertToTime(daySchedule["Close"]);

        // Check if Open and Close times are the same
        const isHoliday = start === end;

        return {
            day,
            schedule: {
                start,
                end,
                isHoliday
            }
        };
    });
    return hourmodel;
}

const convertTo24Hour = (time: string, date: string = new Date().toISOString().split("T")[0]): string => {
    const [hour, minutePart] = time.split(":");
    const minute = minutePart.slice(0, 2);
    const period = time.slice(-2);

    let hour24 = parseInt(hour, 10);

    if (period === "PM" && hour24 !== 12) {
        hour24 += 12;
    } else if (period === "AM" && hour24 === 12) {
        hour24 = 0;
    }

    const hourFormatted = hour24.toString().padStart(2, "0");
    const isoTime = `${date}T${hourFormatted}:${minute}:00.000Z`;
    return isoTime;
};

const transformScheduleToWorkingHours = (schedules) => {
    const workingHours = {};

    schedules.forEach(({ day, schedule }) => {
        const dayKey = day;
        workingHours[dayKey] = {
            Open: schedule.isHoliday ? `${new Date().toISOString().split("T")[0]}T00:00:00.000Z` : convertTo24Hour(schedule.start),
            Close: schedule.isHoliday ? `${new Date().toISOString().split("T")[0]}T00:00:00.000Z` : convertTo24Hour(schedule.end),
        };
    });

    return workingHours;
};
export { modifyStoreData, getStoreDataFromState, transformScheduleToWorkingHours }