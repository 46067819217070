
import { merge, cloneDeep, isEqual, omit, unset, has, isEmpty, remove } from "lodash";
import {
    CataloguePublishStatus as CouponPublishStatus,
    UtcOffset,

} from "@/config/dataConfig";
import { ILoyaltyActiveViewModel, ILoyaltyDataModel } from "@/Interfaces/DataModel/LoyaltyDataModel";

export function expiredPeriodValid(viewModel, isNewLoyalty = true) {

    const currentTime = new Date();
    const validFrom = new Date(viewModel.validFrom);
    const validTo = new Date(viewModel.validTo);
    const allowedTimeDelay = 5 * 60 * 1000
    return isNewLoyalty ?
        (validFrom >= new Date(currentTime.getTime() - allowedTimeDelay) && validTo > currentTime && validTo > validFrom) :
        (validTo > currentTime && validTo > validFrom)
}

export function viewModelAndDataModelSame(LoyaltyDataModel, viewModel) {
    const modelsAreNotSame = !isEqual(
        omit(viewModel, ["validTo", "validFrom"]),
        omit(LoyaltyDataModel, ["validTo", "validFrom"])
    );
    return modelsAreNotSame
}

// export function couponValid(couponActiveViewModel: ILoyaltyActiveViewModel) {

//     const isCouponDistributedValid = couponActiveViewModel.numberOfSlips == (couponActiveViewModel.storesDistribution || []).reduce((total, coupon) => {
//         total += coupon.slipCount
//         return total
//     }, 0)
//     return isCouponDistributedValid

// }

// export function publishedStatusValidCheck(couponDataModel, ViewModel) {
//     const statusModel =
//         ViewModel.status === CouponPublishStatus.publish
//             ? CouponPublishStatus.published
//             : CouponPublishStatus.unPublish;

//     return !isEqual(couponDataModel.status, statusModel);
// }

export function isValidityUpdated(couponDataModel: ILoyaltyDataModel, ViewModel: ILoyaltyDataModel) {
    const isValidFromChanged = Date.parse(couponDataModel.validFrom) != Date.parse(ViewModel.validFrom)
    const isValidToChanged = Date.parse(couponDataModel.validTo) != Date.parse(ViewModel.validTo)


    return isValidFromChanged || isValidToChanged

}


export function NullCheckValidation(omittedData) {
    return Object.entries(omittedData).map((value: any) => {
        return Array.isArray(value[1])
            ? isEmpty(value[1])
            : typeof value[1] === "string"
                ? value[1] === "" || value[1] === null
                : typeof value[1] === "object"
                    ? value[1] === null || Object.values(value[1]).length === 0
                    : has(omittedData, value[1]);
    });
}

