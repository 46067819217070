enum StoreDataModelKeys {
    category = 'category',
    city = 'city',
    contactNumber = 'contactNumber',
    country = 'country',
    district = 'district',
    email = 'email',
    geoCode = 'geoCode',
    pinCode = 'pinCode',
    primaryCategory = 'primaryCategory',
    secondaryPhoneNumber = 'secondaryPhoneNumber',
    state = 'state',
    storeAddress = 'storeAddress',
    storeImage = 'storeImage',
    storeManagerName = 'storeManagerName',
    storeName = 'storeName',
    websiteUrl = 'websiteUrl',
    tags = 'tags',
    sellerId = 'sellerId',
    storeId = 'storeId',
    userId = 'userId',
    regionId = 'regionId',
    gstNumber = 'gstNumber',
    deliveryType = 'deliveryType',
    status = 'status',
    deliveryViewModel = "deliveryViewModel",
    visibleToPublic = "visibleToPublic",
    introduction = "introduction",
    workingHours = "workingHours",
    banner = "banner",
    sections = "sections",
    attributes ="attributes",
    deliveryFee="deliveryFee",
    freeDeliveryPerKm = "freeDeliveryPerKm"
}


interface IStoreDataModel {
    [StoreDataModelKeys.category]: string[];
    [StoreDataModelKeys.city]: string;
    [StoreDataModelKeys.contactNumber]: string;
    [StoreDataModelKeys.country]: string;
    [StoreDataModelKeys.district]: string;
    [StoreDataModelKeys.email]: string;
    [StoreDataModelKeys.geoCode]: string;
    [StoreDataModelKeys.pinCode]: string;
    [StoreDataModelKeys.primaryCategory]: string;
    [StoreDataModelKeys.secondaryPhoneNumber]: string;
    [StoreDataModelKeys.state]: string;
    [StoreDataModelKeys.storeAddress]: string;
    [StoreDataModelKeys.storeImage]: string;
    [StoreDataModelKeys.storeManagerName]: string;
    [StoreDataModelKeys.storeName]: string;
    [StoreDataModelKeys.websiteUrl]: string;
    [StoreDataModelKeys.tags]: string[];
    [StoreDataModelKeys.sellerId]: string;
    [StoreDataModelKeys.storeId]: string;
    [StoreDataModelKeys.userId]: string;
    [StoreDataModelKeys.regionId]: string;
    [StoreDataModelKeys.gstNumber]: string;
    [StoreDataModelKeys.deliveryType]: string[];
    [StoreDataModelKeys.introduction]: string;
    [StoreDataModelKeys.workingHours]:  {};
    [StoreDataModelKeys.sections]: IStoreSection[];
    [StoreDataModelKeys.attributes]?:attributes
}

interface IStoreActiveViewModel {
    [StoreDataModelKeys.category]: string[];
    [StoreDataModelKeys.city]: string;
    [StoreDataModelKeys.contactNumber]: string;
    [StoreDataModelKeys.country]: string;
    [StoreDataModelKeys.district]: string;
    [StoreDataModelKeys.email]: string;
    [StoreDataModelKeys.geoCode]: string;
    [StoreDataModelKeys.pinCode]: string;
    [StoreDataModelKeys.primaryCategory]: string;
    [StoreDataModelKeys.secondaryPhoneNumber]: string;
    [StoreDataModelKeys.state]: string;
    [StoreDataModelKeys.storeAddress]: string;
    [StoreDataModelKeys.storeImage]: string;
    [StoreDataModelKeys.storeManagerName]: string;
    [StoreDataModelKeys.storeName]: string;
    [StoreDataModelKeys.websiteUrl]: string;
    [StoreDataModelKeys.tags]: string[];
    [StoreDataModelKeys.sellerId]: string;
    [StoreDataModelKeys.storeId]: string;
    [StoreDataModelKeys.userId]: string;
    [StoreDataModelKeys.regionId]: string;
    [StoreDataModelKeys.status]: string;
    [StoreDataModelKeys.gstNumber]: string;
    [StoreDataModelKeys.deliveryType]: string[];
    [StoreDataModelKeys.deliveryViewModel]: string;
    [StoreDataModelKeys.introduction]: string;
    [StoreDataModelKeys.workingHours]: {};
    [StoreDataModelKeys.banner]: string;
    [StoreDataModelKeys.sections]: IStoreSection[];
    [StoreDataModelKeys.attributes]?:attributes;
    [StoreDataModelKeys.deliveryFee]?:string;
    [StoreDataModelKeys.freeDeliveryPerKm]?:string;
}

interface IStoreSection {

    count: string,
    name: string,
    position: string,
    sectionData: {
        dataType: string,
        resultData: 
            {
                key: string,
                value: string
            }[]
        
    },
    sectionType: string

}

interface IStoreListViewModel {
    storeName: String,
    storeId: String
}
interface IworkingHour {

    [key: string]: {
        [key: string]: string;
    };

}

interface attributes{
    [key:string]:string
}

export { IStoreActiveViewModel, IStoreDataModel, StoreDataModelKeys, IStoreListViewModel };
