
import AnalyticsCard from "./AnalyticsCard.vue";
import ProductStatistics from "./ProductStatistics.vue";

export default {
  name: "App",
  components: {
    AnalyticsCard,
    ProductStatistics
  },
};
