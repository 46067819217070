


<script>
export default {
  name: 'Skeleton',
};
</script>
<template>
  <div class="skeleton-container">
    <div class="skeleton-card" v-for="n in 12"
         :key="n">
      <div class="skeleton-header"></div>
      <div class="skeleton-body">
        <div class="skeleton-text"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-text skeleton-short"></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.skeleton-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.skeleton-card {
  width: 100%;
  max-width: 300px;
  padding: 16px;
  border-radius: 8px;
  background: #a39ec2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skeleton-header {
  height: 20px;
  width: 60%;
  background: #8489a3;

  margin-bottom: 12px;
  border-radius: 4px;
}

.skeleton-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-text {
  height: 16px;
  background: #8489a3;
  border-radius: 4px;
}

.skeleton-short {
  width: 40%;
}
</style>
