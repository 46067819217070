
import { merge, cloneDeep, isEqual, omit, unset, has, isEmpty, remove } from "lodash";
import {
    CataloguePublishStatus as CouponPublishStatus,
    UtcOffset,

} from "@/config/dataConfig";
import { ICouponActiveViewModel, ICouponDataModel } from "@/Interfaces/DataModel/CouponDataModel";
export function expiredPeriodValid(viewModel, isNewCoupon = true) {

    const currentTime = new Date();
    const validFrom = new Date(viewModel.validFrom);
    const validTo = new Date(viewModel.validTo);
    const allowedTimeDelay = 5 * 60 * 1000
    return isNewCoupon ?
        (validFrom >= new Date(currentTime.getTime() - allowedTimeDelay) && validTo > currentTime && validTo > validFrom) :
        (validTo > currentTime && validTo > validFrom)
}

export function viewModelAndDataModelSame(couponDataModel, viewModel) {
    const modelsAreNotSame = !isEqual(
        omit(viewModel, ["validTo", "validFrom", "status"]),
        omit(couponDataModel, ["validTo", "validFrom", "status"])
    );
    return modelsAreNotSame
}

export function couponValid(couponActiveViewModel: ICouponActiveViewModel) {

    const isCouponDistributedValid = couponActiveViewModel.numberOfSlips == (couponActiveViewModel.storesDistribution || []).reduce((total, coupon) => {
        total += coupon.slipCount
        return total
    }, 0)
    return isCouponDistributedValid

}

export function publishedStatusValidCheck(couponDataModel, ViewModel) {
    let statusModel;
    switch (ViewModel.status) {
        
        case CouponPublishStatus.publish:
          statusModel = CouponPublishStatus.publish;
          break;
        case CouponPublishStatus.published:
          statusModel = CouponPublishStatus.published;
          break;
        case CouponPublishStatus.unPublish:
          statusModel = CouponPublishStatus.unPublish;
          break;
          case CouponPublishStatus.unPublished:
            statusModel = CouponPublishStatus.unPublished;
            break;
        default:
          statusModel = CouponPublishStatus.unPublished;
      }
    return !isEqual(couponDataModel.status, statusModel);
}

export function isValidityUpdated(couponDataModel: ICouponDataModel, ViewModel: ICouponDataModel) {
    const isValidFromChanged = Date.parse(couponDataModel.validFrom) != Date.parse(ViewModel.validFrom)
    const isValidToChanged = Date.parse(couponDataModel.validTo) != Date.parse(ViewModel.validTo)


    return isValidFromChanged || isValidToChanged

}


export function NullCheckValidation(omittedData) {
    return Object.entries(omittedData).map((value: any) => {
        return Array.isArray(value[1])
            ? isEmpty(value[1])
            : typeof value[1] === "string"
                ? value[1] === "" || value[1] === null
                : typeof value[1] === "object"
                    ? value[1] === null || Object.values(value[1]).length === 0
                    : has(omittedData, value[1]);
    });
}

export function couponCodeGeneration(couponCode) {
    return Math.random().toString(36).split('').filter(function (value, index, self) {
        return self.indexOf(value) === index;
    }).join('').substr(2, 6).toUpperCase()
}