import { integrationChannelTemplate, ViewModelStatus } from "@/config/dataConfig";
import { IIntegrationActiveViewModel, IIntergationChannelListViewModel, IntegrationComponent, Integrations } from "@/Interfaces/DataModel/IntegrationDataModel";
import axios from "axios";

export async function loadIntegrationTemplateFromGitHb() {
    try {
        const response = await axios.get(integrationChannelTemplate);
        const jsonData = (typeof response.data === 'string' )? JSON.parse(response.data) : response.data;
        return jsonData
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}


export function convertTemplateDataModelToViewModel(dataModel: any): IIntergationChannelListViewModel[] {
    const result = transformData(dataModel)
    return result

}
function transformData(data: Integrations): IIntergationChannelListViewModel[] {
    return Object.entries(data).map(([key, value]) => {
        const {
            addedAtUtc,
            correlationId,
            lastModifiedBy,
            sellerId,
            parameters,
            userId,
            storeId,
            lastModifiedOn,
            version,
            regionId,
            icon,
            tag,
            integrationName,
            componentId,
            componentName,
            source,
            ...rest
        } = value;


        const emptyValues = Object.fromEntries(
            Object.keys(rest).map(field => [field, ''])
        );
        const emptyValuesForParemeters = Object.fromEntries(
            Object.keys(parameters).map(field => [field, ''])
        );
        return {
            type: getIntegrationName(key) ,
            integrationName: getIntegrationName(key),
            componentId: "",
            componentName: "",
            source: "",
            icon: value.icon,
            tag: value.tag,
            parameters: emptyValuesForParemeters,
            ...emptyValues,
        };
    });
}
function getIntegrationName(key) {
    switch (key) {
        case "whatsapp":
            return "WhatsappCommunication";
        case "facebook":
            return "FaceBookCommunication";
        case "instagram":
            return "InstagramCommunication";
        // Add more cases as needed
        default:
            return key;
    }
}


export function convertTemplateToViewModel(viewModel:IIntergationChannelListViewModel, TemplateViewModel:IIntergationChannelListViewModel[], listViewModel:IIntegrationActiveViewModel[]) {
    let modifiedChannel = {} as any
    const unifiedWhatsapp = createUnifiedDataModel(viewModel, TemplateViewModel);
    unifiedWhatsapp.componentName = `${unifiedWhatsapp.integrationName} Integration`
    const existingItem = listViewModel.find(item => item.integrationName === unifiedWhatsapp.integrationName);
    if (existingItem) {

        modifiedChannel = { ...unifiedWhatsapp }
        modifiedChannel.default = false
    } else {
        modifiedChannel = { ...unifiedWhatsapp }
    }
    return modifiedChannel
}


function createUnifiedDataModel(
    additionalData: any,
    components: IIntergationChannelListViewModel[]
): IIntegrationActiveViewModel {
    let component = null;
    components.some(c => {
        if (c.type === additionalData.integrationName) {
            component = c;
            return true;
        }
        return false;
    });
    if (!component) {
        const metdata = {
            wcVersion: "",
            destination: "",
            customerKey: "",
            customerSecretKey: "",
            email: "",

        }
        return {
            ...additionalData,
            parameters: metdata,
            IsSaved: ViewModelStatus.New


        }

    } else {
        return {
            ...component,
            ...additionalData,
            IsSaved: ViewModelStatus.New
        };
    }
}

export function convertToIntergationChannelDataModel(integrationChannel: IIntergationChannelListViewModel, viewModel: IIntergationChannelListViewModel) {

    viewModel.integrationName = integrationChannel.integrationName,
        viewModel.componentName = integrationChannel.componentName,
        viewModel.componentId = integrationChannel.componentId,
        viewModel.parameters = { ...integrationChannel.parameters }
    return viewModel

}


export function modelValueEmtyOrNot(integrationChannelActiveViewModel: IIntegrationActiveViewModel) {
    const { componentName, source, integrationName, parameters } = integrationChannelActiveViewModel;
    if (componentName.trim() === '') {
        return { isSuccess: false, errorMessage: "Component name cannot be empty." };
    }
    if (source.trim() === '') {
        return { isSuccess: false, errorMessage: "Source cannot be empty." };
    }
    if (integrationName.trim() === '') {
        return { isSuccess: false, errorMessage: "Integration name cannot be empty." };
    }

    for (const key in parameters) {
        if (Object.prototype.hasOwnProperty.call(parameters, key)) {
            if (!parameters[key].trim()) {
                return { isSuccess: false, errorMessage: `${key} cannot be empty.` };
            } else if (key === 'PhoneNumber' || key === 'WhatsappPhoneNumber') {
                if (!/^\d{10}$/.test(parameters[key].trim())) {
                    return { isSuccess: false, errorMessage: `${key} must be exactly 10 digits.` };
                }
            }
        }
    }

    return { isSuccess: true, errorMessage: "" };
}
