import { DeliveryConstant } from "@/config/dataConfig";
import { setImgUrl } from "@/service/fileService";

import store from '@/store'
const catalogueMandatoryHaeding = ["remove","devliveryType", "active", "name", "image", "code", "price", "discount", "quantity"]


import { computed } from "vue";

const isDarkMode = computed(() => store.state.argon.darkMode)

const switchSelector = (status: boolean) => {

    status = typeof status == "string" ? status == 'true' : status

    return status ? `<div class="form-check form-switch ps-0 mt-3"><input class="form-check-input ms-0" type="checkbox" checked="true"/></div>` :
        `<div class="form-check form-switch ps-0 mt-3"><input class="form-check-input ms-0" type="checkbox" /></div>`
}

const catelogueFixedHeader = [


    {
        field: "Remove",
        editable: false,
        cellRenderer: (params: any) => `<button class="btn mt-3"> <i class="fas fa-trash text-primary"> </i> </button>`,
        lockPosition: 'right',
        maxWidth: 100
    },
    {
        field: "active",
        editable: false,
        cellRenderer: (params: any) => switchSelector(params.data?.active),
        rowDrag: true,
        maxWidth: 100
    },
    {
        field: "deliveryAvailable",
        editable: false,
        cellRenderer: (params: any) => switchSelector(params.data?.deliveryAvailable == "HomeDelivery"),
        rowDrag: true,
        maxWidth: 150
    },
    {
        field: "name",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        minWidth: 150,
        autoHeight: true,
        wrapText: true
    },
    {
        field: "code",
        editable: false,
        maxWidth: 120
    },
    {
        field: "price",
        editable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        maxWidth: 120

    },
    {
        field: "discount",
        editable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        maxWidth: 120
    },
    {
        field: "quantity",
        editable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        maxWidth: 120
    }
]

const catalogueListTableHeading = [

    {
        field: "Name",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        flex: 5,
        autoHeight: false,
        wrapText: false,
        tooltipField: "Name"
    },
    {
        field: "Duration",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        flex: 3,
        autoHeight: false,
        wrapText: false,
        tooltipField: "Duration"

    },
    {
        field: "Active in Stores",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        flex: 4,
        cellRenderer: (params: any) => {
            let result = ''
            if (params.data && params.data["Active in Sores"]) {
                for (const storeName of (params.data["Active in Sores"] || [])) {
                    result += `<span class="rounded mb-3 p-1 text-white bg-gradient-primary">
          ${storeName} 
        </span>`
                }
            }
            return `<div class="mt-3 "> <p class="text-xs d-flex flex-wrap "> ${ result?result:'-' } </p> </div>` 
        },
        autoHeight: false,
        wrapText: true
    },
    {
        field: "Published",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        flex: 2,
        cellRenderer: (params: any) => `<span class="rounded m-1 p-1 text-white bg-gradient-secondary text-xs w-50">
        ${params.data?.Published ? 'Yes': 'No' }
         </span>` 
    },
    {
        field: "Open",
        lockPosition: 'right',
        flex: 1,
        cellRenderer: (params: any) => `<i class="text-lg fas fa-arrow-alt-circle-right"></i>`,
    
      }
]


const defaultColDef = {
    flex: 1,
    minWidth: 150,
    sortable: true,
    resizable: true,
};


export const catalogueListFixedHeader = [
    {
        field: "Name",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        flex: 5,
        autoHeight: false,
        wrapText: false,
        tooltipField: "Name"
    },
    {
        field: "Duration",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        flex: 3,
        autoHeight: false,
        wrapText: false,
        tooltipField: "Duration"
    },
    {
        field: "Commodities",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        maxWidth: 200
    },
    {
        field: "Active Stores",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        maxWidth: 200
    },
    {
        field: "Published",
        editable: false,
        sortable: true,
        filter: 'agTextColumnFilter',
        flex: 2,
        cellRenderer: (params: any) => switchSelector(params.data?.Published),
    }
]

export { catelogueFixedHeader as AgGridHeaderConfig, defaultColDef, catalogueMandatoryHaeding, catalogueListTableHeading }