export const omitedData=[
"currencyCode",
"issueVersion",
"loyalityDefinitionId",
"storeId",

    
]


export function  createNewCssStyleOfLoaylty(isDarkMode: Boolean){
    return `
    <style>
      .swal2-input {
        font-size: 12px; 
        padding: 5px; 
        background-color: ${isDarkMode ? "#111c44" : "#fff"}; 
        color: ${isDarkMode ? "#fff" : "#000"};
      }
  .swal2-styled.swal2-cancel {
        font-size: 15px; 
        padding: 5px 10px;
        border-radius: 0.5rem;
      }
              .swal2-styled.swal2-confirm {
        font-size: 15px; 
        padding: 5px 10px;
      }
    </style>
    <h2 style="font-size: 1.0rem; margin: 0;">Enter New User Name</h2>
  `
  }