<template>
  <div  class="mt-4 h-100 card mt-md-0 " style="margin:30px;">
    <div style="opacity:.5">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center px-8">
        <h6>{{ title }}<span class="tag" style="margin-left: 3px">PRO ✨</span></h6>
      </div>
    </div>
    <div ref="lineChart" class="chart" style="width: 100%; height: 400px"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { useStore } from "vuex";

export default {
  name: "AnalyticsCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isDarkMode() {
      return this.$store.state.argon.darkMode;
    },
    cardClass() {
      return this.isDarkMode ? 'card-dark' : 'card-light'; // Add class based on dark mode
    },
  },
  watch: {
    isDarkMode: {
      handler() {
        this.initChart();
      },
    },
  },
  methods: {
    initChart() {
      const chartElement = this.$refs.lineChart;
      const myChart = echarts.init(chartElement);

      const option = {
        title: {
          text: "No Data Found",
          subtext: "",
          left: "center",
          top: "top",
          textStyle: {
            fontSize: 10,
            fontWeight: "bold",
            color: "#7c7d86",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: ["January", "February", "March", "April", "May", "June", "July"],
          axisLine: {
            lineStyle: {
              color: this.isDarkMode ? '#ffffff' : '#000000',
            },
          },
          axisLabel: {
            textStyle: {
              color: this.isDarkMode ? '#ffffff' : '#000000',
            },
          },
          splitLine: {
            lineStyle: {
              color: this.isDarkMode ? '#333333' : '#dddddd',
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: this.isDarkMode ? '#ffffff' : '#000000',
            },
          },
          axisLabel: {
            textStyle: {
              color: this.isDarkMode ? '#ffffff' : '#000000',
            },
          },
          splitLine: {
            lineStyle: {
              color: this.isDarkMode ? '#ffffff' : '#dddddd',
            },
          },
        },
        series: [
          {
            name: "Sales",
            type: "line",
            data: [0, 0, 0, 0, 0, 0, 0],
            areaStyle: {},
            lineStyle: {
              color: this.isDarkMode ? 'white' : 'black',
            },
          },
        ],
      };

      myChart.setOption(option);
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style scoped>
.card-dark {
  background-color: #081235; /* Dark background color */
  color: white; /* Light text color */
}

.card-light {
  background-color: white; /* Light background color */
  color: black; /* Dark text color */
}

.chart {
  position: relative;
}
.tag {
  /* position: absolute; */
  /* top: 4px; */
  background-color: #144888;
  color: white;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
}
</style>
