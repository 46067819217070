import { ICollectionDataModel, ICollectionListViewModel, IProductsTableViewModel } from "@/Interfaces/DataModel/productDataModel";
import { pick, merge, omit, isEqual, cloneDeep, reduce } from "lodash";
import {
  CataloguePublishStatus,
  CommonConfig,
  UserViewConstants,
  ViewModelStatus as CollectionStatus,
  commodityDataTypes,
} from "@/config/dataConfig";


function DataModelToListViewModel(collectionData: ICollectionDataModel): ICollectionListViewModel {
  const dateModify = (dateString: string) => {
    const [month, date, year] = (new Date(dateString)).toLocaleDateString('en', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/')
    return year + '-' + month + '-' + date
  }

  return merge(
    pick(collectionData, [
      "regionId",
      "catalogueId",
      "sellerId",
      "storeId",
      "userId",
      "catalogueName",
      "catalougeVersion",
      "remark",
      "status",
      "stores",
      "tags",
    ]),
    {
      isSaved: CollectionStatus.Saved,
      validRange: dateModify(collectionData.validFromDate) + ' to ' + dateModify(collectionData.validToDate),
      publishStatus: collectionData.status === CataloguePublishStatus.published || collectionData.status === CataloguePublishStatus.publish,
      commoditiesCount: Object.keys(collectionData.commodities).length,
      commodities: collectionData.commodities,
      storesCount: collectionData.stores.length,
      stores: collectionData.stores
    }
  );
}

function DataModelToListViewModels(collection: ICollectionDataModel[]): ICollectionListViewModel[] {

  return collection.map(DataModelToListViewModel);
}

function catelogueListViewModelToActiveDataModel(
  collectionListViewModel,
  tableActiveViewModel?
) {
  const status = collectionListViewModel.publishStatus
    ? CataloguePublishStatus.publish
    : collectionListViewModel.isSaved === CollectionStatus.Saved &&
      collectionListViewModel.status === CataloguePublishStatus.publish
      ? CataloguePublishStatus.unPublish
      : CataloguePublishStatus.unPublish;

  const validRange = collectionListViewModel.validRange.split(" to ");
  const validFromDate = validRange[0];
  const validToDate = validRange[1];
  const commoditties = tableActiveViewModel
    ? tableActiveViewModel.reduce((commodityModel, tableRow) => {
      commodityModel[tableRow.commodityId] = Object.keys(tableRow)
        .filter((o) => o !== "commodityId")
        .map((item) => ({
          name: item,
          value: tableRow[item].toString(),
          type: setTypeOfFieldValue(item, tableRow[item]),
          facet: false,
          optional: false,
          searchable: false,
        }));
      return commodityModel;
    }, {})
    : collectionListViewModel.commodities;
  const catelogue = merge({}, collectionListViewModel, {
    status,
    validFromDate,
    validToDate,
  });
  catelogue.commodities = commoditties;
  catelogue.commoditiesCount = commoditties.length;
  return catelogue;
}
function setTypeOfFieldValue(key: string, value: any) {
  const intType = ["price", "discount", "quantity"];
  if (key.toLowerCase() === "image") {
    return commodityDataTypes.file;
  } else if (intType.includes(key) || (!isNaN(value) && key.toLowerCase() !== "active")) {
   
    return commodityDataTypes.number;
  } else {
    return commodityDataTypes.text;
  }
}

function isValidObject(obj: any): boolean {
  return typeof obj === 'object' && obj !== null;
}

function isValidArray(arr: any): boolean {
  return Array.isArray(arr);
}

function validateItem(item: any): { isValid: boolean; errorMessage?: string } {
  if (!isValidObject(item)) {
    return { isValid: false, errorMessage: "Invalid item structure." };
  }

  if (item.value === undefined || item.value === null || item.value === '') {
    return { isValid: false, errorMessage: "Please fill required details." };
  }

  if (item.type.toLowerCase() === 'number') {
    const numericValue = Number(item.value);
    if (isNaN(numericValue)) {
      return { isValid: false, errorMessage: "Please fill proper details." };
    }
  }
  if (item.name === "discount") {
    const discountValue = Number(item.value);
    if (isNaN(discountValue) || discountValue < 0 || discountValue > 100) {
      return { isValid: false, errorMessage: "Discount must be a number between 0 and 100." };
    }
  }

  return { isValid: true };
}

function hasValues(obj: IProductsTableViewModel): { isSuccess: boolean; errorMessage: string } {
  if (!isValidObject(obj)) {
    return { isSuccess: false, errorMessage: "Passed wrong data." };
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const valueArray = obj[key];

      if (isValidArray(valueArray)) {
        for (const item of valueArray) {
          const validation = validateItem(item);
          if (!validation.isValid) {
            return { isSuccess: false, errorMessage: validation.errorMessage };
          }
        }
      }
    }
  }

  return { isSuccess: true, errorMessage: "" };
}



export { DataModelToListViewModel, DataModelToListViewModels, catelogueListViewModelToActiveDataModel, hasValues }