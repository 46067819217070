<template>
  <div class="custom-suggestion">
   
    <div class="" :class="isDarkMode ? 'tag-inputDarkTheme' : 'tag-input'">
      <!-- Display tags as editable spans inside the input field -->
      <span
        v-for="(tag, index) in tags"
        :key="index"
        :class="isDarkMode ? 'tagDarkTheme' : 'tag'"
        @blur="handleTagEdit(index, $event)"
      >
        {{ tag }}
        <span class="close-icon" @click="removeTag(index)">&times;</span>
      </span>
      <!-- Input field for entering new tags -->
      <input
        v-if="tags.length < 2"
        v-model="inputValue"
        :class="isDarkMode ? 'custom-input' : 'form-control'"
        placeholder="Search :fieldName=searchValue ...Please press enter"
        list="suggestions"
        @focusin="showSuggestions = true"
        @blur="showSuggestions = false"
        @input="handleInput"
        @keydown.down.prevent="highlightNext"
        @keydown.up.prevent="highlightPrevious"
        @keydown.enter.prevent="handleEnter"
      />

      <!--      <datalist id="suggestions"  :class="isDarkMode ? 'suggestionsDrakTheme' : 'suggestions'">-->
      <!--        <option  v-for="(word, index) in suggestions" :key="index" >{{word}}</option>-->
      <!--      </datalist>-->
      
    </div>

  
    <ul
      v-if="showSuggestions"
      :class="isDarkMode ? 'suggestionsDrakTheme' : 'suggestions'"
    >
      <li
        v-for="(suggestion, index) in filteredSuggestions"
        :key="index"
        :class="{ active: index === highlightedIndex }"
        @mousedown="selectSuggestion(suggestion)"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion }}
      </li>
    </ul>
    <ArgonButton
      class="btn-icon ms-2"
      color="white"
      :class="isDarkMode ? 'text-white' : 'text-primary'"
      :variant="isDarkMode ? 'outline' : 'fill'"
      @click="handleEnter"
    >
      <span class="btn-inner--text">Apply Search</span>
    </ArgonButton>
    <div v-if="showError" class="error-message">{{errorMessage}}</div>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from "vuex";

const store = useStore();
const inputValue = ref("");
const suggestions = ref(["Name", "Email", "Contact Number"]);
let highlightedIndex = ref(-1);
const tags = ref([]);
const showError = ref(false);
const showSuggestions = ref(false); // Ensure to initialize as a reactive reference
const errorMessage = ref('Format is searchKey = searchValue');


const isDarkMode = computed(() => store.state.argon.darkMode);
const emit = defineEmits(["searchWithTagkey"]);

function handleInput(event) {
  inputValue.value = event.target.value;
  highlightedIndex.value = -1; 
  showError.value = false; 
  showSuggestions.value = true;
  
}

function selectSuggestion(suggestion) {
  inputValue.value = suggestion + " = ";
  highlightedIndex.value = -1; 
  showSuggestions.value = false; 
}

function handleEnter() {
  const tagValue = inputValue.value.trim();
  let validate = isValidTag(tagValue);
  if (validate.isSuccess) {
    if (tags.value.length < 2) {
      tags.value.push(tagValue);
      clearInput();
      handleSearch();
    } else if (highlightedIndex.value >= 0) {
      selectSuggestion(filteredSuggestions.value[highlightedIndex.value]);
    }
  } else {
    showError.value = true; 
    errorMessage.value = validate.errorMessage
  }
}

function removeTag(index) {
  tags.value.splice(index, 1);
  showSuggestions.value = false;
  emit("searchWithTagkey", tags.value);
}

function handleTagEdit(index, event) {
  const editedTag = event.target.innerText.trim();
  let validate = isValidTag(editedTag);
  if (validate.isSuccess) {
    tags.value[index] = editedTag;
  } else {
    showError.value = true; 
    errorMessage.value = validate.errorMessage
  }
}

function highlightNext() {
  if (highlightedIndex.value < filteredSuggestions.value.length - 1) {
    highlightedIndex.value++;
  }
}

function highlightPrevious() {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value--;
  }
}

const filteredSuggestions = computed(() => {
  const regex = new RegExp(inputValue.value.trim(), "i");
  return suggestions.value.filter((suggestion) => regex.test(suggestion));
});

function clearInput() {
  inputValue.value = "";
  highlightedIndex.value = -1;
  showSuggestions.value = false;
}

function isValidTag(value) {
  const regex = /^\s*(\w+)[\w\s\-!@#$%^&*()+=|\\[\]{};':",.<>/?]*\s*=\s*(.+?)\s*$/;
  const match = value.match(regex);

  if (!match) return {isSuccess:false,errorMessage:'Invalid search  format'};

  const key = match[1].toLowerCase();
  const tagValue = match[2].trim();
  if (key === "email") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let data= emailRegex.test(tagValue);
    return {isSuccess:data,errorMessage:'Invalid email format'}
  } else if (key === "contact") {
    const phoneRegex = /^\d{10}$/;
    let data= phoneRegex.test(tagValue);
    return {isSuccess:data,errorMessage:'Invalid contactNumber format'}
  } else {
    let data =  tagValue.length > 0;
    return {isSuccess:data,errorMessage:'Invalid search  format'}
  }
}

function handleSearch() {
  emit("searchWithTagkey", tags.value);
}
</script>

<style scoped>
.custom-suggestion {
  position: relative;
}

.tag-input {
  display: inline-flex;
  align-items: center;
  width: 75%;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.tag-inputDarkTheme {
  display: inline-flex;
  align-items: center;
  width: 75%;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #495057;
  background-color: #051139;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.tagDarkTheme {
  background-color: #142252;
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.tag {
  background-color: #1197ef;
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.tag .close-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  font-weight: bold;
}

.error-message {
  color: red;
  margin-top: 0.5rem;
  font-size: 12px;
}

.suggestions {
  position: absolute;
  z-index: 1000;
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 1;
  padding-top: 3;
  width: 75%;
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
}

.suggestionsDrakTheme {
  position: absolute;
  z-index: 1000;
  background-color: #051139;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 1;
  padding-top: 3;
  width: 75%;
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
}

.suggestions li {
  padding: 10px;
  cursor: pointer;
}

.suggestions li.active {
  background-color: #1180ef;
  color: #ee7f10;
}

.form-control {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
}

</style>
