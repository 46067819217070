<template>
  <div >
    <div ref="lineChart" class="chart" style="width: 100%; height: 400px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { useStore } from "vuex";
const store = useStore();

export default {
  name: "LineChart",
  mounted() {
    this.initChart();
  },
  computed: {
    isDarkMode() {
      return this.$store.state.argon.darkMode;
    },
  },
  watch: {
    isDarkMode: {
      handler(newValue, oldValue) {
        this.initChart();
      },
    },
  },
  methods: {
    initChart() {
      const chartElement = this.$refs.lineChart;
      const myChart = echarts.init(chartElement);

      const option = {
        title: {
          text: "No Data Found",
          subtext: "",
          left: "center",
          top: "top",
          textStyle: {
            fontSize: 10,
            fontWeight: "bold",
            color: "#7c7d86",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: ["January", "February", "March", "April", "May", "June", "July"],
          axisLine: {
            lineStyle: {
              color: this.isDarkMode ? "#ffffff" : "#000000", // Dark or light color for axis line
            },
          },
          axisLabel: {
            textStyle: {
              color: this.isDarkMode ? "#ffffff" : "#000000", // Dark or light color for axis labels
            },
          },
          splitLine: {
            lineStyle: {
              color: this.isDarkMode ? "#333333" : "#dddddd", // Dark or light color for grid lines
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: this.isDarkMode ? "#ffffff" : "#000000", // Dark or light color for axis line
            },
          },
          axisLabel: {
            textStyle: {
              color: this.isDarkMode ? "#ffffff" : "#000000", // Dark or light color for axis labels
            },
          },
          splitLine: {
            lineStyle: {
              color: this.isDarkMode ? "#ffffff" : "#dddddd", // Dark or light color for grid lines
            },
          },
        },
        series: [
          {
            name: "Sales",
            type: "line",
            data: [0, 0, 0, 0, 0, 0, 0],
            areaStyle: {},
            lineStyle: {
              color: this.isDarkMode ? "white" : "black",
            },
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 400px;
}
</style>
