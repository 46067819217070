import { commodityDataTypes } from "@/config/dataConfig";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import Swal from "sweetalert2";
import store from '@/store'
import { computed } from "vue";

const isDarkMode = computed(() => store.state.argon.darkMode )

const commoditymandatoryfield = ["Description", "Brand", "IsPerishable", "Price", "Manufacturer"]
//captilize datatype is removing do to inconsistence in showing datatype 
const dataTypes = Object.values(commodityDataTypes).map(item => item.toString())

const boolSelector = () => ({
  component: "agSelectCellEditor",
  params: {
    values: ["True", "False"],
  },
  popup: true,
})

const dataTypeSelectore = () => ({
  component: "agSelectCellEditor",
  params: {
    values: dataTypes,
  },
  popup: true
})

const switchSelector = (status: boolean) => {
  return status ? `<div class="form-check form-switch ps-0 mt-3"><input class="form-check-input ms-0" type="checkbox" checked="true"/></div>` :
    `<div class="form-check form-switch ps-0 mt-3"><input class="form-check-input ms-0" type="checkbox" /></div>`

}

const commodityTableHaeding = [
  {
    field: "Add",
    editable: false,
    cellRenderer: (params: any) => `<button class="btn btn mt-3"> <i class="fas fa-plus text-primary"></i> </button>`,
    lockPosition: 'right',
    minWidth: 80,
    maxWidth: 120
  },
  {
    field: "Remove",
    editable: false,
    cellRenderer: (params: any) => commoditymandatoryfield.includes(params.data?.name) ? '' : `<button class="btn mt-3"> <i class="fas fa-trash text-primary"> </i> </button>`,
    lockPosition: 'right',
    minWidth: 80,
    maxWidth: 120
  },
  {
    field: "name",
    editable: (params: any) => !(commoditymandatoryfield.includes(params.data?.name)),
    sortable: true,
    rowDrag: true,
    filter: 'agTextColumnFilter',
    minWidth: 300
  },
  {
    field: "value",
    editable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 300
  },
  {
    field: "type",
    editable: true,
    sortable: true,
    cellEditorSelector: dataTypeSelectore,
    minWidth: 100,
  },
  {
    field: "searchable",
    editable: false,
    sortable: true,
    cellRenderer: (params: any) => switchSelector(params.data?.searchable),
    minWidth: 100,
    maxWidth: 250,

  },
  {
    field: "facet",
    editable: false,
    sortable: true,
    cellRenderer: (params: any) => switchSelector(params.data?.facet),
    minWidth: 100,
    maxWidth: 250
  },
  {
    field: "optional",
    editable: false,
    sortable: true,
    cellRenderer: (params: any) => switchSelector(params.data?.optional),
    minWidth: 100,
    maxWidth: 250
  }
]


const commodityListTableHeading = [
  {
    field: "Commodity Name",
    editable: false,
    rowDrag: true,
    cellRenderer: (params: any) => params.data?.Name ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg"> ${params.data.Name.slice(0, 64)} </p>` : "commodityName",
    minWidth: 600,
    maxWidth: 400,
  },
  {
    field: "CommodityType",
    editable: false,
    cellRenderer: (params: any) => params.data?.CommodityType ? `<p class="text-xs mt-3 font-weight-bolder text-wrap"> ${params.data.CommodityType} </p>` : "CommodityType",
    minWidth: 120,
    maxWidth: 240
  },
  {
    field: "Code",
    editable: false,
    cellRenderer: (params: any) => params.data?.Code ? `<p class="text-xs mt-3 font-weight-bolder text-wrap"> ${params.data.Code} </p>` : "commodityCode",
    minWidth: 120,
    maxWidth: 240
  },
  
  {
    field: "Category",
    sortable: true,
    filter: 'agTextColumnFilter',
  cellRenderer: (params: any) => {
    let result = ''
    if(params.data?.Category ) {
    for(const category of (params.data.Category || [])) {
         result += `<span class="rounded m-1 p-1 text-white bg-gradient-primary">
      ${category}
    </span>`
    }
  }
    return `<div class="mt-3"> <p class="text-xs d-flex flex-wrap"> ${ result } </p> </div>` 
  },

  },
  {
    field: "Tags",
    sortable: true,
    filter: "agTextColumnFilter",
    cellRenderer: (params: any) => {
      let result = ''
      if(params.data?.Tags) {
      for(const tag of (params.data.Tags || [])) {
           result += `<span class="rounded m-1 p-1  text-white bg-gradient-primary">
        ${tag}
      </span>`
      }
    }
    return `<div class="mt-3"> <p class="text-xs d-flex flex-wrap"> ${ result } </p> </div>` 
    },
  },
  {
    field: "Open",
    lockPosition: 'right',
    cellRenderer: (params: any) => `<i class="text-lg fas fa-arrow-alt-circle-right"></i>`,

  }
]

const defaultColDef = {
  flex: 1,
  minWidth: 150,
  sortable: true,
  resizable: true,
};

export enum CommodityTypes {
  Electronics = "Electronics",
  Furniture = "Furniture"
}

export const CommodityTypesList = [ CommodityTypes.Electronics, CommodityTypes.Furniture ]

export enum BulkImportTemplateUrl{
  url = "https://raw.githubusercontent.com/AICorpGlobal/public-buyconn-release-artifiacts/master/buyconn-templates/bulkimportTemplate.v1.csv",
  fileName = 'bulkImportTemplate.v1.csv'
}

export async function alertMsg(linkValue){
  const result = await Swal.fire({
    title: "Are you sure?",
    text: `Do you want to Link by ${linkValue}?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, save it!",
    cancelButtonText: "Cancel",
  });
  return result
}
export {
  commoditymandatoryfield, commodityTableHaeding as AgGridHeaderConfig, defaultColDef, commodityListTableHeading
}