
import { IGroupDataModel } from "@/Interfaces/DataModel/ChatDataModel";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import { chatApi } from "../Common/ApiRoutes";
import { ApiService as ApiServiceClass } from "../Common/ApiService";
import { config } from "@/config/apiConfig"

const ApiService = new ApiServiceClass(config.signalR.baseUrl)

function getGroups(userId: string, regionId: string,token:string): Promise<IResponseDataModel<IGroupDataModel[]>>  {
    const { url, method }: { url: string, method: string } = chatApi.getGroups
    return ApiService.SendRequest(url, method, { query: { userId:userId, skip:0,take:10} })
}

function getUsersInGroup(groupId: string, regionId: string,):  Promise<IResponseDataModel<IGroupDataModel[]>> {
    const { url, method }: { url: string, method: string } = chatApi.getUsersInGroup(groupId)
    return ApiService.SendRequest(url, method, { query: { regionId } })
}

function getChatHistory(data: any,startWindow:string,endWindow:string):  Promise<IResponseDataModel<IGroupDataModel[]>> {
    const { url, method }: { url: string, method: string } = chatApi.getChatHistory(data.groupId)
    return ApiService.SendRequest(url, method, {query: { startWindow, endWindow } })
}
function createNewMessage(data: any):  Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = chatApi.CreateMessage(data.groupId)
    return ApiService.SendRequest(url, method, { data}  )
}
function JoinGroup(data: any):  Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = chatApi.JoinGroup(data.groupId)
    return ApiService.SendRequest(url, method, { data, query:{connectionId:""} })
}
function CreateNewGroup (data:any): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = chatApi.CreateGroup()
    return ApiService.SendRequest(url, method, { data})
}
function LeaveGroup (data:any): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = chatApi.LeaveGroup(data.groupId)
    return ApiService.SendRequest(url, method, { data})
}
export {
    getGroups,
    getUsersInGroup,
    getChatHistory,
    createNewMessage,
    JoinGroup,
    CreateNewGroup,
    LeaveGroup
}