import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getGroups } from "./apiService/ChatService";
import { IGroupDataModel } from "@/Interfaces/DataModel/ChatDataModel";
import { CommunicationService } from "./Common/communicationService";
import store from '@/store'
import { config } from "@/config/apiConfig";

const signalRConfig = config.signalR.chat

export const chatCommunicationService = new CommunicationService(signalRConfig.url, store.getters["userCred/getAccessToken"])

export async function getAndJoinUserGroups(sellerId: string, regionId: string, token: string) {
  const resp = await getGroups(sellerId, regionId, token)
  if (resp.result && resp.result[0]) {
    resp.result[0].userId = resp.result[0].groupId.split('+')[1]
    await ReJoinAllGroups(resp.result[0])
  }
}

export async function ReJoinAllGroups(groupData: IGroupDataModel) {
  await chatCommunicationService.invokeAMethod(signalRConfig.invoke.rejoinInGroups, groupData);
  return groupData;
}

export async function createNewGroup(groupData: IGroupDataModel) {
  await chatCommunicationService.invokeAMethod(signalRConfig.invoke.createAGroup, groupData);
  return groupData;
}

export async function JoinGroup(groupData: IGroupDataModel) {
  await chatCommunicationService.invokeAMethod(signalRConfig.invoke.joinAGroup, groupData);
  return groupData;
}
export async function updateUserInfo(userInfo:any){
  await chatCommunicationService.invokeAMethod(signalRConfig.invoke.UpdateUserInfo, userInfo);
}


export async function initCommunications(sellerId: string, RegionId: string, routeName: string, token: string) {
  if (chatCommunicationService.getConnectionState() !== "Connected") {
    await chatCommunicationService.start(token);
    await getAndJoinUserGroups(sellerId, RegionId, token);
    await chatCommunicationService.registerAListener("OnMessage", (MessageDtaModel) => {
      if (MessageDtaModel.userType !== "store" && routeName !== "Chat") {
        const messageCount = store.getters["getUnreadMessageCount"] + 1;
        store.dispatch("updateUnreadMessageCount", messageCount);
      }
    });
  } else {
    setTimeout(() => {
      initCommunications(sellerId, RegionId, routeName, token)
    }, 500);
  }
}
export function createNewRoomCssStyle(isDarkMode, storeList) {

  // Create the dropdown options dynamically

  let dropdownOptions = '<option value=""  selected>Select an item</option>';


  storeList.forEach(store => {

    dropdownOptions += `<option value="${store.storeId}">${store.storeName}</option>`;

  });


  return `

    <style>

      .swal2-input {

        font-size: 12px; 

        padding: 5px; 

        background-color: ${isDarkMode ? "#111c44" : "#fff"}; 

        color: ${isDarkMode ? "#fff" : "#000"};

      }

      .swal2-styled.swal2-cancel {

        font-size: 15px; 

        padding: 5px 10px;

        border-radius: 0.5rem;

      }

      .swal2-styled.swal2-confirm {

        font-size: 15px; 

        padding: 5px 10px;

      }

    </style>



    <h3 style="font-size: 1.0rem; margin: 0;">Enter Room Details</h3>



    <input id="room-name" type="text" style="margin-bottam:1rem;width:75%;" placeholder="Enter Group name" />

    <h3 style="font-size: 1.0rem; margin: 2;padding:4;margin-top: 1rem;">Select Store Name</h3>



    <select id="store-select" style="width:75%;>

      ${dropdownOptions}

    </select>

  `;

}
