import swal from "sweetalert2";
import type { SweetAlertResult } from "sweetalert2"

import { IValidationDataModel, IValidationViewModel } from "@/Interfaces/DataModel/LoginDataModel";
import { IUserActiveViewModel } from "@/Interfaces/DataModel/UserDataModel";
import { CreateAOtpRequestForValidation, CreateAValidationEntryRequest, DeleteValidationEntry } from "./apiService/ValidationSerice";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";

async function OTPHandler(userActiveViewModel: IUserActiveViewModel, isDarkMode: Boolean): Promise<boolean> {
  const { sellerId, userId, regionId, phoneNumber } = userActiveViewModel
  const validationViewModel: IValidationViewModel = getValidationViewModel(userId, phoneNumber, sellerId, regionId);

  CreateAOtpRequestForValidation(
    sellerId,
    userId,
    {
      email: userActiveViewModel.email,
      tagKey: phoneNumber
    }
  )
  const result = await ConfirmRecivedOTP(userId, sellerId, regionId, phoneNumber, isDarkMode);
  return result.isConfirmed ? result.value?.isSuccess : false;
}

async function ConfirmRecivedOTP(userId: string, sellerId: string, regionId: string, phoneNumber: string, isDarkMode: Boolean, msg= "",): Promise<SweetAlertResult<any>> {
  return await swal.fire({
    title: ` 
      <style>
      .swal2-container .swal2-html-container{
      padding:0rem 0rem 0rem
      }
      .swal2-title {

  margin: 0;
 padding: 1em 1em 1em;
  color: #595959;
  font-size: 0em;
}
      </style>
  <h5 style="font-size: 1.0rem; margin: 0;padding:1em 1em 1em;">Phone Number Validation</h5>
`,
    html: `
    <style>
          .swal2-title {

  margin: 0;
  padding: 1em 1em 1em;
  color: #595959;
    font-size: 0em;
  }
    .swal2-input {
      font-size: 12px; 
      padding: 5px; 
     background-color: ${isDarkMode ? "#111c44" : "#fff"}; 
      color: ${isDarkMode ? "#fff" : "#000"};
    }
      .swal2-container .swal2-html-container{
      padding:0rem 0rem 0rem
      }
.swal2-styled.swal2-cancel {
      font-size: 15px; 
      padding: 5px 10px;
      border-radius: 0.5rem;
    }
            .swal2-styled.swal2-confirm {
      font-size: 15px; 
      padding: 5px 10px;
    }
  </style>
    <div style="font-size: 13px; margin-bottom: 0px;">
      ${msg === "" ? "Enter OTP received on the number:" : "Invalid OTP entered"}
    </div>
   
  `,
    input: "text",
    showCancelButton: true,
    confirmButtonText: "Verify",
    showLoaderOnConfirm: true,
    preConfirm: (otp: string) => sendRequestWithOTP(userId, sellerId, regionId, phoneNumber, otp),
  });

}

async function sendRequestWithOTP(userId: string, sellerId: string, regionId: string, phoneNumber: string, otp: string): Promise<IResponseDataModel<IValidationDataModel> | void> {
  const validationViewModel: IValidationViewModel = getValidationViewModel(userId, phoneNumber, sellerId, regionId, otp);
  const resp = await CreateAValidationEntryRequest(
    sellerId,
    userId,
    validationViewModel
  )

  if (!resp.isSuccess) {
    // TODO: check status
    swal.showValidationMessage(resp.responseCode === 409 ? "Try different number" : "Invalid OTP")
  } else {
    return resp
  }
}


function getValidationViewModel(userId: string, tagKey: string, sellerId: string, regionId: string, otp = ""): IValidationViewModel {
  return {

    tagKey,
    otp,
    sellerId,
    regionId,
    validationStatus: ""
  };
}


function DeleteOldNumber(sellerId: string, userId: string, oldNumber: string, newNumber?: string) {
  const numbersAreNotSame = newNumber ? (oldNumber !== newNumber) : true
  if (numbersAreNotSame) {
    DeleteValidationEntry(
      sellerId,
      userId,
      oldNumber
    );
  }
}

export default { DeleteOldNumber, OTPHandler }