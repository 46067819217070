import { IGroupDataModel } from "@/Interfaces/DataModel/ChatDataModel";

export function ToEmptyInitChatRoomViewModel(regionId,sellerId,userId,storeId,roomName,userName):IGroupDataModel{
    return{
        regionId,
        sellerId,
        userId,
        storeId,
        creatorId:userId,
        groupId:"",
        groupName:roomName,
        groupStatus:"",
        groupType:"public",
        userName,
        userType:"Store",
        groupDescription:"",
        lastGroupViewTimeStamp: new Date().toISOString()

    }
}