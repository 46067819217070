<template>
  <div class="form-group">
    <label :for="id">
      <slot />
    </label>
    <textarea
      :id="id"
      class="form-control"
      :class="['form-control', darkMode ? 'dark-mode' : 'light-mode']"
      :rows="rows"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "ArgonTextarea",
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 5,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
/* Light mode styles */
.light-mode {
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text */
  border: 1px solid #ddd; /* Light border */
}

.light-mode:focus {
  background-color: #ffffff; /* Same background as normal */
  color: #000000;
  border-color: #aaa; /* Slightly darker border on focus */
  outline: none; /* Remove default focus outline */
}

/* Dark mode styles */
.dark-mode {
  background-color: #111c44; /* Dark background */
  color: #ffffff; /* White text */
  border: 1px solid #555; /* Dark border */
}

.dark-mode:focus {
  background-color: #111c44; /* Same background as normal */
  color: #ffffff;
  border-color: #777; /* Slightly lighter border on focus */
  outline: none; /* Remove default focus outline */
}
</style>
