import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import { IWorkFlowDataModel } from "@/Interfaces/DataModel/WorkFlowDataModel";
import {
    createAWorkFlow,
    downloadTemplate,
    getAllWorkFlow,
    updateAWorkFlow
} from "@/service/apiService/WorkFlowService";
import { cardTemplate } from "@/service/apiService/CardsActionService";
import { PropertySchema, Schema } from "@/Interfaces/DataModel/EnquiryDataModel";
import { ViewModelStatus } from "@/config/dataConfig";
import { fieldsToExcludeinWorkFlow } from "@/views/configs/enquiryManagementConfig";


async function getAllEnquiries(sellerId: string): Promise<IResponseDataModel<IWorkFlowDataModel[]>> {
    const response: IResponseDataModel<IWorkFlowDataModel[]> = await getAllWorkFlow(sellerId)
    return response
}

async function LoadCardTemplate(): Promise<any> {
    const response = await cardTemplate();
    return response.data
}
async function LoadWorkFlowTemplate(): Promise<any> {
    const response = await downloadTemplate()
    return response.data
}
function initializeValue(type: string): any {
    switch (type) {
        case 'string':
            return '';
        case 'number':
            return 0;
        case 'boolean':
            return false;
        case 'array':
            return [];
        case 'object':
            return {};
        default:
            return null;
    }
}
function convertToDynamicFieldsViewModel(data) {
    const initializedObject = Object.keys(data.properties).reduce((acc, key) => {
        const property = data.properties[key];
        const type = property.type;

        acc[key] = initializeValue(type);

        return acc;
    }, {} as Record<string, any>);
    return initializedObject
}
export function convertToDynamicworkFlowViewModel(data) {
    // console.log(data,"data")
    // const initializedObject = Object.keys(data).reduce((acc, key) => {
    //     console.log(acc,key)
    //     acc[key] = "";
    //
    //     return acc;
    // }, {} as Record<string, any>);
    return data
}
function generateDynamictFieldsInterface(schema: Schema): string {
    const properties = schema.properties;
    let interfaceStr = 'SchemaProperties {\n';

    for (const [key, prop] of Object.entries(properties)) {
        if (isPropertySchema(prop)) {
            const type = prop.type;
            let tsType: string;

            switch (type) {
                case 'string':
                    tsType = 'string';
                    break;
                case 'number':
                    tsType = 'number';
                    break;
                case 'boolean':
                    tsType = 'boolean';
                    break;
                case 'array':
                    tsType = 'any[]';
                    break;
                case 'object':
                    tsType = '{}';
                    break;
                default:
                    tsType = 'any';
            }

            interfaceStr += `  ${key}${prop.required ? '' : '?'}: ${tsType};\n`;
        }
    }

    interfaceStr += '}';

    return interfaceStr;
}

type TypeMap = { [key: string]: string | TypeMap | Array<TypeMap> | 'any' };

export function generateDynamicWorkFlowInterface(obj: any, interfaceName: string = 'DynamicInterface'): { [interfaceName: string]: TypeMap } {
    const processProperties = (properties: any): TypeMap => {
        const result: TypeMap = {};
        for (const [key, value] of Object.entries(properties)) {
            let tsType: string | TypeMap | Array<TypeMap> = 'any';

            switch (typeof value) {
                case 'string':
                    tsType = 'string';
                    break;
                case 'number':
                    tsType = 'number';
                    break;
                case 'boolean':
                    tsType = 'boolean';
                    break;
                case 'object':
                    if (Array.isArray(value)) {
                        if (value.length > 0 && typeof value[0] === 'object') {
                            tsType = [generateDynamicWorkFlowInterface(value[0])];
                        } else {
                            tsType = 'Array<any>';
                        }
                    } else if (value === null) {
                        tsType = 'any';
                    } else {
                        tsType = processProperties(value);
                    }
                    break;
                default:
                    tsType = 'any';
            }

            result[key] = tsType;
        }

        return result;
    };

    return {
        [interfaceName]: processProperties(obj)
    };
}


function isPropertySchema(obj: any): obj is PropertySchema {
    return typeof obj === 'object' && obj !== null && 'type' in obj;
}

function convertWorkFlowDataModelToViewModel(DataModel: any) {
    return {
        ...DataModel,
        saved: DataModel.workflowId ? ViewModelStatus.Saved : ViewModelStatus.New
    }
}

export function ToEmptyWorkFlowListViewModel(sellerId: string, regionId: string, userId: string, dataModel) {
    dataModel.sellerId = sellerId;
    dataModel.regionId = regionId;
    dataModel.userId = userId;
    dataModel.saved = ViewModelStatus.New
    return dataModel
}
export function updateOrNewWorkFlow(dataModel) {
    if (dataModel.workflowId) {
        return updateAWorkFlow(dataModel.sellerId, dataModel)
    } else {
        return createAWorkFlow(dataModel.sellerId, dataModel)
    }
}
type FieldType = 'text' | 'number' | 'select' | 'password' | 'multiselect' | 'date' | 'disabled';

const specificFieldRules: { [key: string]: { type: FieldType; options?: string[] } } = {
    'assignee': { type: 'multiselect', options: [] },
    'participants': { type: 'multiselect', options: [] },
    // 'createdOn':{type:'date'},
    totalEffortInHours: { type: 'disabled' },
    'state': { type: 'select', options: ['Active', 'Inactive', 'Pending'] },
    'priority': { type: 'select', options: ['High', 'Medium', 'Low'] },
    'workflowType': { type: 'select', options: ['ProductEnquiry', 'IssueTicket'] }
};
export function genrateWorkFlowTemplate(dataModel, userDataModel) {
    const updatedDataModel = { ...dataModel };
    Object.keys(specificFieldRules).forEach(key => {
        delete updatedDataModel[key];
    });
    const dynamicFields = Object.keys(updatedDataModel).map(key => {


        const value = updatedDataModel[key];
        let type: FieldType = 'text';

        if (typeof value === 'number') {
            type = 'number';
        } else if (Array.isArray(value)) {

            type = 'select';
        }

        const placeholder = key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1');
        const required = fieldsToExcludeinWorkFlow.indexOf(key) === -1;

        return {
            name: key,
            type: type,
            placeholder: placeholder,
            validate: (value: any) => commonValidate(value, type, placeholder, required),
            required: required
        };
    });
    const specificFields = Object.keys(specificFieldRules).map(key => {
        const rule = specificFieldRules[key];
        return {
            name: key,
            type: rule.type,
            placeholder: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
            validate: (value: any) => commonValidate(value, rule.type, key, fieldsToExcludeinWorkFlow.indexOf(key) === -1),
            required: rule.type === 'disabled' ? false : fieldsToExcludeinWorkFlow.indexOf(key) === -1,
            options: rule.type !== 'date' && rule.options?.length === 0 ? userDataModel : rule.options
        };
    });
    const data = [...dynamicFields, ...specificFields];
    return data
}
function commonValidate(value: any, type: FieldType, fieldName: string, required: boolean): string | undefined {
    if (required && !value) {
        return `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required!`;
    }
    if (type === 'number' && isNaN(value)) {
        return `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} must be a number!`;
    }
    return undefined;
}

export function convertToUserListModel(userDataModel) {

    const extractedDataArray = userDataModel.map(item => ({
        userId: item.userId,
        userName: item.name
    }));
    return extractedDataArray
}
export function convertToWorkFlowDataModel(viewModel, dataModel) {
    dataModel.description = viewModel.description;
    dataModel.workflowType = viewModel.workflowType;
    dataModel.assignee = viewModel.assignee;
    dataModel.state = viewModel.state;
    dataModel.priority = viewModel.priority;
    dataModel.participants = [viewModel.participants];
    dataModel.addedAtUtc = new Date().toISOString();
    dataModel.lastModifiedOn = new Date().toISOString();
    dataModel.lastUpdateTimeStamp = new Date().toISOString();
    dataModel.lastUpdateTime = new Date().toISOString();
    dataModel.createdOn = dataModel.createdOn ? new Date(dataModel.createdOn).toISOString() : new Date().toISOString();
    dataModel.name = viewModel.name;
    dataModel.workflowId = viewModel.workflowId;
    dataModel.totalEffortInHours = viewModel.totalEffortInHours;
    return dataModel
}

export { LoadWorkFlowTemplate, convertWorkFlowDataModelToViewModel, getAllEnquiries, LoadCardTemplate, convertToDynamicFieldsViewModel, generateDynamictFieldsInterface }